import { Api } from '@/plugins/api';
import { pinia } from '@/plugins/store';

/**
 * Set a channel as favorite in the API.
 * @param channelId The channel.
 * @param favorite Whether the channel should be set as favorite.
 * @throws {Error} When the API returns an error.
 */
export async function setFavorite(channelId: string, favorite = true): Promise<void> {
	const url = Api.instance.getEndpoint('user/epg/set_favorite', {
		session: pinia().getSession,
		ch_id: channelId,
		set: favorite ? '1' : '0',
	});

	const response = await fetch(url, { redirect: 'follow' });

	if (!response.ok) {
		throw new Error('Nepodařilo se nastavit oblíbený kanál.');
	}
}

/**
 * Get all favorite channels from the API.
 * @return The favorite channels.
 * @throws {Error} When the API returns an error.
 */
export async function getFavorites(): Promise<string[]> {
	const url = Api.instance.getEndpoint('user/personal/favoriteids', {
		session: pinia().getSession,
		array: '1',
	});

	const response = await fetch(url, { redirect: 'follow' });

	if (!response.ok) {
		throw new Error('Nepodařilo se načíst oblíbené kanály.');
	}

	return response.json() as Promise<string[]>;
}

/**
 * Reset all favorites in the API.
 * @throws {Error} When the API returns an error.
 */
export async function resetAllFavorites(): Promise<void> {
	const url = Api.instance.getEndpointLegacy({
		function: 'epg/clear_user_prefs',
		which: 'favorite_channels',
		session: pinia().getSession,
	});

	const response = await fetch(url, { redirect: 'follow' });

	if (!response.ok) {
		throw new Error('Nepodařilo se resetovat oblíbené kanály.');
	}
}
